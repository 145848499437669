import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";
import { AutoComplete } from "primereact/autocomplete";
import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";

const SEARCH = gql`
  query getSearch($key: String!) {
    containers(
      filters: {
        or: [
          { Title: { containsi: $key } }
          { Author: { containsi: $key } }
          { Tag: { containsi: $key } }
          { Description: { containsi: $key } }
        ]
      }
    ) {
      data {
        id
        attributes {
          Title
          Author
          SessionID
          day {
            data {
              id
            }
          }
          session {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export default function Header() {
  const [items, setitems] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const menu = useRef(null);
  const onClick = () => {
    localStorage.clear();
    window.location.reload();
  };
  let item = [
    {
      label: "Logout",
      icon: "bi bi-power error-msg",
      command: () => {
        onClick();
      },
    },
  ];

  const [executeQuery] = useLazyQuery(SEARCH, {
    onCompleted: (someData) => {
      var dataL = [{ label: "Programme", code: "PO", items: [] }];
      someData.containers.data.map((poster) => {
        var pos = {
          label: poster.attributes.Title,
          value: poster.id,
          SessionID: poster.attributes.SessionID,
          session: poster.attributes.session.data.id,
          day: poster.attributes.day.data.id,
        };
        dataL[0].items.push(pos);
      });
      setitems(dataL);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const searchCountry = (event) => {
    executeQuery({
      variables: { key: event.query },
    });
  };

  const setSelected = (item) => {
    console.log(item);
    window.location.href =
      "/session/" + item.day + "/" + item.SessionID + "/" + item.session;
  };

  return (
    <>
      {/* ======= Header =======  fixed-top*/}
      <div className="row top-stripe">
        <div className="col-md-12 d-flex justify-content-between">
          <small>International Water Association</small>

          <div>
            <div className="d-flex justify-content-center">
              <a href="https://www.thesourcemagazine.org/"><small className="mx-2">The Source</small></a>
              <a href="https://www.iwaconnectplus.org/"><small className="mx-2">IWA ConnectPlus</small></a>
              <a href="https://www.iwapublishing.com/"><small className="mx-2">IWA Publishing</small></a>
            </div>
          </div>
        </div>
      </div>
      <header
        id="header"
        className="header  d-flex align-items-center justify-content-between"
      >
        <div className="d-flex align-items-center justify-content-between">
          <a href="/" className="logo d-flex align-items-center">
            <img
              className="p-2 ms-3"
              src={require("../assets/img/logo.png")}
              alt=""
            />
          </a>
        </div>

        <div className="align-items-center justify-content-between d-none d-md-flex">
          <nav className="header-nav ">
            <ul className="d-flex align-items-center">
              <li className="nav-item dropdown pe-3">
                <NavLink
                  className=""
                  activeclassname="btn btn-primary btn-sm"
                  to="/"
                >
                  Home
                </NavLink>
              </li>
              {/*<li className="nav-item pe-3">
                <NavLink
                  className=""
                  activeclassname="btn btn-primary btn-sm"
                  to="/session"
                >
                  Programme
                </NavLink>
              </li>*/}
              
              
              <li className="nav-item pe-3">
                <a
                  className=""
                  href={window.location.origin+ "#KeyNotes"}
                >
                  Keynotes
                </a>
              </li>

              <li className="nav-item pe-3">
                <a
                  className=""
                  href={window.location.origin+ "#ThematicTracks"}
                >
                  Thematic Tracks
                </a>
              </li>


              <li className="nav-item pe-3">
                <a
                  className=""
                  href={window.location.origin+ "#DailyView"}
                >
                  Daily Views
                </a>
              </li>
              <li className="nav-item pe-3">
                <a
                  className=""
                  href="https://strapiimagetwo.s3.eu-west-2.amazonaws.com/IWA_World_Water_Congress_and_Exhibition_Website_ad08b11fbb.pdf"
                  target="_blank"
                >
                  Programme Book 
                </a>
              </li>
              <li className="nav-item pe-3">
                <a
                  className=""
                  href="https://iwa-network.org/wp-content/uploads/2023/02/IWA_WWCE_2022_Copenhagen_Report.pdf"
                  target="_blank"
                >
                  Report
                </a>
              </li>
            </ul>
          </nav>
          <Inplace closable className="search-icon me-2">
            <InplaceDisplay>
              <i className="pi pi-search me-2 mt-1 fs-4"></i>
            </InplaceDisplay>
            <InplaceContent>
              <div className="search-bar ms-auto me-3">
                <i className="bi bi-search-heart mr-2"></i>
                <AutoComplete
                  placeholder="Seach..."
                  value={selectedItem}
                  suggestions={items}
                  completeMethod={searchCountry}
                  onChange={(e) => setSelectedItem(e.value)}
                  onSelect={(e) => setSelected(e.value)}
                  field="label"
                  optionGroupLabel="label"
                  optionGroupChildren="items"
                />
              </div>
            </InplaceContent>
          </Inplace>

          {/* End Search Bar */}
          <nav className="header-nav">
            <ul className="d-flex align-items-center">
              <li className="nav-item dropdown pe-3">
                <Avatar
                  onClick={(event) => menu.current.toggle(event)}
                  label={JSON.parse(
                    localStorage.getItem("token")
                  ).user.username.slice(0, 1)}
                  shape="circle"
                  size="large"
                  style={{ backgroundColor: "#9c27b0", color: "#ffffff" }}
                />
                <Menu ref={menu} popup model={item} />
              </li>
              {/*<li className="nav-item dropdown pe-3">
              <button onClick={() => onClick()} type="button" className="btn btn-light btn-sm"> <i className="bi bi-power error-msg"></i> &nbsp; Logout</button>
            </li>*/}
              {/* End Profile Nav */}
            </ul>
          </nav>
        </div>
      </header>
      {/* End Header */}
    </>
  );
}
