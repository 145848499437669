import "./App.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
  ApolloProvider,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import useToken from "./Components/useToken";


//Pages
import Home from "./Pages/Home";
import Session from "./Pages/Session";
import SessionDetail from "./Pages/Session-detail";
import Login from "./Pages/Login";

//Components
import Header from "./Components/Header";
import Footer from "./Components/Footer";

const httpLink = new HttpLink({
  uri: "https://wwce-2022-backend.herokuapp.com/graphql",
  //uri: "http://localhost:1337/graphql",
});
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers//
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: JSON.parse(localStorage.getItem("token"))
        ? "Bearer " + JSON.parse(localStorage.getItem("token")).jwt
        : null,
    },
  }));

  return forward(operation);
});
const errorControl = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(
        " [GraphQL error]: Message",
        message,
        ", Location: ",
        locations,
        ", Path: ",
        path
      );

      if (message === "Unauthorized") {
        localStorage.clear();
        window.location.reload();
      }
    });
  }
  if (networkError) {
    console.log(networkError);
    if (networkError.toString().search("401") !== -1) {
      localStorage.clear();
      window.location.reload();
    }
  }
});
const client = new ApolloClient({
  link: concat(authMiddleware, errorControl.concat(httpLink)),
  //uri: 'http://localhost:1337/graphql',
  cache: new InMemoryCache(),
});

function App() {

  /**/


  const { token, setToken } = useToken();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const HLAuthToken = params.get('HLAuthToken');
  
  const Username = params.get('Username');
  const auth = localStorage.getItem("token");
  

  if (!token) {
    if(HLAuthToken !== null && auth == null){
      var jwt = {
        jwt: HLAuthToken,
        user: {
          username: Username
        }
      }
      setToken(jwt);
      window.location = window.location.href.split("?")[0];
    }else{
      return (
        <ApolloProvider client={client}>
          {" "}
          <Login setToken={setToken} />{" "}
        </ApolloProvider>
      );
    }
    
  }
  
  return (
    <Router>
      <ApolloProvider client={client}>
        <div className="App layout-wrapper container-fluid m-0 p-0" onContextMenu={(e)=> e.preventDefault()}>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/session" element={<Session />} />
            <Route
              exact
              path="/session/:day/:sessionId/:sessionNo"
              element={<SessionDetail />}
            />
          </Routes>
          <Footer />
        </div>
      </ApolloProvider>
    </Router>
  );
}

export default App;
