import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import {  Pagination,  A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";

const THEMES = gql`
  query getThemes {
    days(sort: "Date:asc") {
      data {
        id
        attributes {
          Title
          Date
        }
      }
    }
    themes(sort: "id:asc") {
      data {
        id
        attributes {
          Title
          IconName
          ColorCode
        }
      }
    }
    containers(
      sort: ["day.id:asc", "SortNo:desc"]
      pagination: { start: 0, limit: 300 }
      filters: { ContentCategory: { eq: "Keynote_Video" } }
    ) {
      data {
        id
        attributes {
          Title
          SessionID
          theme {
            data {
              attributes {
                IconName
                ColorCode
              }
            }
          }
          day {
            data {
              id
              attributes {
                Title
                Date
              }
            }
          }
          session {
            data {
              id
              attributes {
                Title
              }
            }
          }
        }
      }
    }
  }
`;
const THEME = gql`
  query getSessions($id: ID!) {
    theme(id: $id) {
      data {
        id
        attributes {
          Title
          IconName
          containers(
            pagination: { start: 0, limit: 300 }
            filters: {
              or: [
                { ContentCategory: { eq: "Daily_Summary_Video" } }
                { ContentCategory: { eq: "Keynote_Video" } }
                { ContentCategory: { eq: "Opening_Ceremony" } }
                { ContentCategory: { eq: "Title" } }
              ]
            }
          ) {
            data {
              id
              attributes {
                Title
                SessionID
                theme {
                  data {
                    attributes {
                      IconName
                      ColorCode
                    }
                  }
                }
                day {
                  data {
                    id
                    attributes {
                      Title
                    }
                  }
                }
                session {
                  data {
                    id
                    attributes {
                      Title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const SESSION = gql`
  query getSessions($id: ID!) {
    day(id: $id) {
      data {
        id
        attributes {
          Title
          Date
          containers(
            sort: ["session.id:asc", "SortNo:asc"]
            pagination: { start: 0, limit: 300 }
            filters: {
              or: [
                { ContentCategory: { eq: "Daily_Summary_Video" } }
                { ContentCategory: { eq: "Keynote_Video" } }
                { ContentCategory: { eq: "Opening_Ceremony" } }
                { ContentCategory: { eq: "Title" } }
              ]
            }
          ) {
            data {
              id
              attributes {
                Title
                SessionID
                theme {
                  data {
                    attributes {
                      IconName
                      ColorCode
                    }
                  }
                }
                day {
                  data {
                    id
                    attributes {
                      Title
                    }
                  }
                }
                session {
                  data {
                    id
                    attributes {
                      Title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function Session() {
  const { loading, error, data } = useQuery(THEMES);

  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayBasicTwo, setDisplayBasicTwo] = useState(false);

  const [blockedPanel, setBlockedPanel] = useState(false);

  const [sessions, setSessions] = useState({});
  const [sessionsday, setSessionsday] = useState({});

  const [activeIndex, setActiveIndex] = useState(0);

  const [executeQuery] = useLazyQuery(THEME, {
    onCompleted: (someData) => {
      if (someData) {
        setSessions(someData);
        setDisplayBasic(true);
        setBlockedPanel(false);
      }
    },
    onError: (err) => {
      setBlockedPanel(false);
    },
  });

  const [executeQuery2] = useLazyQuery(SESSION, {
    onCompleted: (someData) => {
      if (someData) {
        setSessionsday(someData);
        setDisplayBasicTwo(true);
        setBlockedPanel(false);
      }
    },
    onError: (err) => {
      setBlockedPanel(false);
    },
  });

  const onClick = (position) => {
    setBlockedPanel(true);

    executeQuery({
      variables: { id: position },
    });
  };

  const onClick2 = (position) => {
    setBlockedPanel(true);
    executeQuery2({
      variables: { id: position },
    });
  };

  const renderFooter = (name) => {
    return <></>;
  };

  const onHide = (name) => {
    setSessions({});
    setDisplayBasic(false);

    setSessionsday({});
    setDisplayBasicTwo(false);
  };

  if (loading) {
    return (
      <BlockUI blocked="true" fullScreen>
        <ProgressSpinner />
      </BlockUI>
    );
  }
  if (error) {
    setBlockedPanel(false);
    return;
  }

  return (
    <>
      {blockedPanel ? (
        <BlockUI blocked={blockedPanel} fullScreen>
          <ProgressSpinner />
        </BlockUI>
      ) : (
        <></>
      )}
      <main id="main" className="main">
        <div className="pagetitle mt-5">
          <div className="container ps-0 ">
            <div className="row">
              <div className="col-md-8 ps-0 title-page">
                <h2 className="fw-bold fw-4">
                  <span>Programme |</span>&nbsp;The IWA World Water Congress &
                  Exhibition is the global event for water professionals
                  covering the full water cycle.
                </h2>
                <p>
                  As the Congress rotates through cities and countries each
                  event has an extra emphasis on issues of specific interest to
                  the region. The upcoming edition taking place in Copenhagen
                  will have as key focus the digital economy, smart and liveable
                  cities, entrepreneurship and diffusion of innovation, climate
                  change adaptation , community and customer engagement, and
                  sustainability.
                </p>
              </div>
              <div className="col-md-4">
                <img
                  className=""
                  src={require("../assets/img/community.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Page Title */}
        <section className="container dashboard">
          <div className="row">
            {/* Left side columns */}
            <div className="col-lg-12">
              <TabView
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
              >
                <TabPanel header="Days">
                  <Swiper
                    modules={[Pagination, A11y]}
                    spaceBetween={10}
                    slidesPerView={5}
                    pagination={{ clickable: true }}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    {data.days.data.map((date) => (
                      <SwiperSlide key={date.id}>
                        <div className="p-0  cursor">
                          <div className="card p-3">
                            <i class="bi bi-calendar-week mt-2 mb-4"></i>
                            <p className="fw-600 mb-1">
                              {moment(date.attributes.Date).format(
                                "dddd DD, MMM"
                              )}
                            </p>
                            <p className="fw-500 mb-3">
                              <small>{date.attributes.Title}</small>
                            </p>
                            <button
                              onClick={() => onClick2(date.id)}
                              type="button"
                              class="btn btn-primary"
                            >
                              Explore
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </TabPanel>
                <TabPanel header="Tracks">
                  <Swiper
                    modules={[Pagination, A11y]}
                    spaceBetween={10}
                    slidesPerView={6}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    {data.themes.data.map((date) => (
                      <SwiperSlide key={date.id}>
                        <div className="p-0  cursor">
                          <div
                            className="card h-100 d-flex justify-content-between min-h-100 p-3"
                            style={{
                              background: "#" + date.attributes?.ColorCode,
                            }}
                          >
                            <img
                              className="img-track"
                              src={require("../assets/img/" +
                                date.attributes.IconName +
                                ".png")}
                              alt=""
                            />
                            <div>
                              <p className="fw-600 mb-3">
                                {date.attributes.Title}
                              </p>
                              <button
                                onClick={() => onClick(date.id)}
                                type="button"
                                class="btn btn-primary"
                              >
                                Explore
                              </button>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </TabPanel>
              </TabView>
            </div>
            {/* End Left side columns */}
          </div>
        </section>
      </main>
      {/* End #main */}
      <Dialog
        header={
          "Sessions belonging to Track " +
          sessions?.theme?.data?.id +
          " - " +
          sessions?.theme?.data?.attributes.Title
        }
        visible={displayBasic}
        style={{ width: "70vw" }}
        footer={renderFooter("displayBasic")}
        onHide={() => onHide("displayBasic")}
      >
        <div className="table-responsive">
          <table className="table  table-hover mb-1  table center table-centered table-nowrap table">
            <tbody>
              {sessions?.theme?.data?.attributes.containers.data.map(
                (theme) => (
                  <tr
                    key="theme.id"
                    style={{
                      background:
                        "#" +
                        theme?.attributes?.theme?.data?.attributes?.ColorCode,
                    }}
                  >
                    <td className="align-middle">
                      <img
                        className="img-track"
                        src={require("../assets/img/" +
                          theme?.attributes?.theme?.data?.attributes?.IconName +
                          ".png")}
                        alt=""
                      />
                    </td>
                    <td className="align-middle text-center min-max-100">
                      <p className="fs-6 m-0 fw-regular">
                        {theme?.attributes?.day?.data?.attributes?.Title}
                      </p>
                    </td>
                    <td className="align-middle text-center min-max-100">
                      <p className="fs-6 m-0 fw-regular">
                        {theme?.attributes?.session?.data?.attributes?.Title}
                      </p>
                    </td>
                    <td className="align-middle w-100">
                      <p className="fs-6 m-0 fw-light">
                        {theme?.attributes?.Title}
                      </p>
                    </td>
                    <td className="align-middle min-max-125">
                      <Link
                        to={`/session/${theme?.attributes?.day?.data?.id}/${theme?.attributes?.SessionID}/${theme?.attributes?.session?.data?.id}`}
                      >
                        <button type="button" class="btn btn-primary">
                          View details
                        </button>
                      </Link>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </Dialog>

      <Dialog
        header="Sessions of the day"
        visible={displayBasicTwo}
        style={{ width: "70vw" }}
        onHide={() => onHide("displayBasic")}
      >
        <div className="table-responsive">
          <table className="table  table-hover mb-1  table center table-centered table-nowrap table">
            <tbody>
              {console.log(sessionsday)}
              {sessionsday?.day?.data?.attributes.containers.data.map(
                (theme) => (
                  <tr
                    key="theme.id"
                    style={{
                      background:
                        "#" +
                        theme?.attributes?.theme?.data?.attributes?.ColorCode,
                    }}
                  >
                    <td className="align-middle">
                      <img
                        className="img-track"
                        src={require("../assets/img/" +
                          theme?.attributes?.theme?.data?.attributes?.IconName +
                          ".png")}
                        alt=""
                      />
                    </td>
                    <td className="align-middle text-center min-max-100">
                      <p className="fs-6 m-0 fw-regular">
                        {theme?.attributes?.session?.data?.attributes?.Title}
                      </p>
                    </td>
                    <td className="align-middle w-100">
                      <p className="fs-6 m-0 fw-light">
                        {theme?.attributes?.Title}
                      </p>
                    </td>
                    <td className="align-middle min-max-125">
                      <Link
                        to={`/session/${theme?.attributes?.day?.data?.id}/${theme?.attributes?.SessionID}/${theme?.attributes?.session?.data?.id}`}
                      >
                        <button type="button" class="btn btn-primary">
                          View details
                        </button>
                      </Link>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </Dialog>
    </>
  );
}
