import React, { useState } from "react";
import PropTypes from "prop-types";
import { gql, useMutation } from "@apollo/client";
import { ProgressSpinner } from "primereact/progressspinner";
import { BlockUI } from "primereact/blockui";
import { Dialog } from "primereact/dialog";
import { Checkbox } from 'primereact/checkbox';

const LOGIN = gql`
  mutation getLogin($email: String!, $password: String!) {
    login(input: { identifier: $email, password: $password }) {
      jwt
      user {
        username
      }
    }
    createActivity( data: { username: $email, Action: "LOGIN"}) {
      data {
        attributes{
          username
        }
      }
    }
  }
  
`;

export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [checked, setChecked] = useState(false);
  const [blockedPanel, setBlockedPanel] = useState(false);
  const [errorMsg, seterrorMsg] = useState();

  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayBasicTwo, setDisplayBasicTwo] = useState(false);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const NoAccess = params.get("NoAccess");

  const [executeQuery] = useMutation(LOGIN, {
    onCompleted: (someData) => {
      if (someData) {
        seterrorMsg("");
        setToken(someData?.login);
        setBlockedPanel(false);
      }
    },
    onError: (err) => {
      console.log(err);
      seterrorMsg(err?.message);
      setBlockedPanel(false);
    },
  });

  const onHide = (name) => {
    setDisplayBasic(false);
  };

  const onClick = (position) => {
    setDisplayBasic(true);
  };

  const onHideTwo = (name) => {
    setDisplayBasicTwo(false);
  };

  const onClickTwo = (position) => {
    console.log('test')
    setDisplayBasicTwo(true);
  };

  const handleSubmit = async (e) => {
    console.log(username, password, checked)
    if(username === undefined || password === undefined || checked === false){
      alert('Please fill all the fields..!');
    }
    else{
      setBlockedPanel(true);
      e.preventDefault();
      executeQuery({
        variables: {
          email: username,
          password: password,
        },
      });
    }
    
  };

  return (
    <>
      {blockedPanel ? (
        <BlockUI blocked={blockedPanel} fullScreen>
          <ProgressSpinner />
        </BlockUI>
      ) : (
        <></>
      )}
      <main className="login-bg">
        <div className="container">
          <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <a
                      href="/"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <img src={require("../assets/img/logo.png")} alt="" />
                    </a>
                  </div>
                  {/* End Logo */}
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          IWA World Water Congress & Exhibition Content
                        </h5>
                        <p className="text-center small">
                          IWA members, please use the button below to log in
                          using your Connect Plus credentials.
                        </p>
                      </div>
                      <div className="col-12 text-center">
                      <small className="error-msg">
                        {NoAccess === "true" ? "You Dont Have Access..!" : ""}
                        {errorMsg}
                      </small>
                        <button
                          className="mt-1 btn btn-dark w-100"
                          onClick={() => {
                            window.open(
                              "https://iwa-network.force.com/idp/login?app=0sp8e000000PAvW&isdtp=p1",
                              "_self"
                            );
                          }}
                        >
                          Login – Connect Plus users
                        </button>
                      </div>
                      <hr className="mb-1" />
                      <div className="col-12 text-center">
                        <small class="fs-7 mb-2">
                          Other delegates, please click the below button to login
                        </small>
                        <button
                          className="mt-1 btn btn-info w-100"
                          onClick={() => {
                            onClick();
                          }}
                        >
                          Login - Other Delegates
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      {/* End #main */}

      <Dialog
        header={""}
        visible={displayBasic}
        style={{ width: "40vw" }}
        breakpoints={{ '960px': '75vw', '641px': '960vw' }}
        onHide={() => onHide("displayBasic")}
      >
        <div className="container">
          <form
            className="row g-3 needs-validation"
            noValidate=""
            onSubmit={handleSubmit}
          >
            <div className="col-12">
              <label htmlFor="yourUsername" className="form-label">
                Username
              </label>
              <div className="input-group has-validation">
                <span className="input-group-text" id="inputGroupPrepend">
                  @
                </span>
                <input
                  type="text"
                  name="username"
                  className="form-control"
                  id="yourUsername"
                  required=""
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-12">
              <label htmlFor="yourPassword" className="form-label">
                Password
              </label>
              <input
                type="password"
                name="password"
                className="form-control"
                id="yourPassword"
                required=""
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="col-12 d-flex">
               <Checkbox
                    id="checked"
                    name="checked"
                    onChange={e => setChecked(e.checked)} checked={checked}
                ></Checkbox>
                <div className="ms-2">I've read and accept the <u onClick={() => {
                            onClickTwo();
                          }}>terms & conditions</u>.</div>
            </div>
            
            <div className="col-12">
              <small className="error-msg">
                {NoAccess === "true" ? "You Dont Have Access..!" : ""}
                {errorMsg}
              </small>
            </div>
            <div className="col-12">
              <button  className="btn btn-primary w-100" type="submit">
                Login 
              </button>
            </div>
          </form>
        </div>
      </Dialog>

      <Dialog
        header={""}
        visible={displayBasicTwo}
        style={{ width: "80vw" }}
        breakpoints={{ '960px': '75vw', '641px': '960vw' }}
        onHide={() => onHideTwo("displayBasicTwo")}
      >
        <p ><span >Terms and conditions of use</span></p>

        <p >Welcome to our website. These are our terms and conditions for use of the website, which you may access in several ways, including but not limited to the World Wide Web via&nbsp;wwce-content.iwaconnectplus.org, PDA, mobile phone and RSS feeds. Please read the below information carefully as it is legally binding on you. In these terms and conditions, when we say the &ldquo;IWA Site&rdquo; we mean the digital information network operated by or on behalf of the International Water Association (&ldquo;IWA&rdquo;), regardless of how you access the network, as well as any IWA apps whether you access those via an IWA Site or a third party site. However you access the IWA Site, you agree to be bound by these terms and conditions.</p>

        <p ><span >1. Use of material appearing on the IWA Site</span></p>

        <p >Your use of the IWA Site is for your own personal and non-commercial use only. You acknowledge that, as between the IWA and you, the IWA is the sole owner of all content on the IWA Site, including, without limitation, all applicable copyrights, patents, trademarks, trade secrets, trade names, logos, and other intellectual property rights thereto, as well as text, images, graphics, logos, audio, video and other material appearing on the IWA Site (&ldquo;IWA Content&rdquo;).</p>

        <p >The IWA Site and the IWA Content are protected by the copyright laws and other intellectual property laws of the United Kingdom, and are protected globally by applicable international copyright treaties.</p>

        <p >You may download and print extracts from the IWA Content for your own personal and non-commercial use only, provided you maintain and abide by any author attribution, copyright or trademark notice or restriction in any material that you download or print. You may not use any IWA Content, in part or in full, for any other purpose without our prior written approval. Except as expressly authorised by the IWA, you are not allowed to create a database in electronic or paper form comprising all or part of the material appearing on the IWA Site.</p>

        <p >Use of our content, other than as permitted by these terms and conditions, requires the express consent of the IWA. Explicit attribution to the IWA must be made in the event that the material, or any part thereof, is reproduced in any form, written or electronic. Please contact us at&nbsp;<a href="mailto:water@iwahq.org" >water@iwahq.org</a>.</p>

        <p >For all other inquiries about distribution or reproduction of the materials, please contact&nbsp;<a href="mailto:water@iwahq.org" >water@iwahq.org</a>.</p>

        <p ><span >2. Disclaimer of liability</span></p>

        <p >To the extent permitted at law, we do not accept any responsibility for any statement in the content on the iwa-network.org. Nothing in the IWA Content is provided for any specific purpose or at the request of any particular person. For the avoidance of confusion, we will not be liable for any loss caused as a result of your doing, or not doing, anything as a result of viewing, reading or listening to the IWA Content or any part of it. You can access other sites via links from the IWA Site. These sites are not under our control and we are not responsible in any way for any of their contents.</p>

        <p >We give no warranties of any kind concerning the IWA Site or the IWA Content. In particular, we do not warrant that the IWA Site or any of its contents is virus free. You must take your own precautions in this respect as we accept no responsibility for any infection by virus or other contamination or by anything that has destructive properties.</p>

        <p >Although we will do our best to provide constant, uninterrupted access to the IWA Site, we do not guarantee this. We accept no responsibility or liability for any interruption or delay.</p>

        <p ><span >3. User content</span></p>

        <p >Users of our site may be permitted to submit content for publication in various areas of the IWA Site.</p>

        <p >When you submit content to us, you agree and represent that you have created that content, or you have received permission from, or are authorised by, the owner of any part of the content to submit it to the IWA Site.</p>

        <p >You or the owner of the content still own the copyright in the content sent to us, but by submitting content to us, you are granting us an unconditional, irrevocable, non-exclusive, royalty-free, fully transferable, perpetual worldwide license to use, publish and/or transmit, and to authorise third-parties to use, publish and/or transmit your content in any format and on any platform, either now known or hereinafter invented.</p>

        <p >You acknowledge and agree that when you post content on the IWA Site or view content provided by others, you are doing so at your own discretion and risk, including any reliance on the accuracy, completeness, of that content. You further acknowledge and agree that the views expressed by you and other users in that content do not necessarily reflect the views of the IWA, and we do not support or endorse any user content. You acknowledge that we have no obligation to pre-screen, monitor, review, or edit any content posted by you and other users on the IWA Site.</p>

        <p >We, or authorised third parties, reserve the right to cut, crop, edit or refuse to publish, your content at our or their sole discretion. We may remove your content from use at any time.</p>

        <p >We accept no liability in respect of any content submitted by users and published by us or by authorised third parties.</p>

        <p >You warrant that the content you submit to us is not obscene, threatening, harassing, libellous, deceptive, fraudulent, invasive of another&rsquo;s privacy, offensive, defamatory of any person or illegal. You warrant that the content you submit to us does not infringe any patent, trademark, trade secret, copyright, or other intellectual or proprietary or privacy right of any party or individual. You agree not to (i) post content which is deliberately intended to upset or harm other users; (ii) use the IWA Site to post or otherwise transmit content that victimises, harasses, degrades, or intimidates an individual or group of individuals on the basis of any impermissible classification, including, without limitation, religion, gender, sexual orientation, race, colour, creed, ethnicity, national origin, citizenship, age, marital status, military status or disability; (iii) post or otherwise transmit any content that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of the IWA Site or any computer software or hardware or telecommunications equipment; (iv) upload or otherwise transmit any content, or take any other actions with respect to your use of the IWA Site, that would constitute, or would otherwise encourage, criminal conduct or give rise to civil liability; or (v) use the IWA Site for commercial purposes, including, without limitation, submitting any material to solicit funds or to promote, advertise or solicit the sale of any goods or services.</p>

        <p >You understand that the technical processing and transmission of the IWA Site may involve (i) transmissions over various networks; and (ii) changes to content to conform and adapt to technical requirements of connecting networks or devices. The IWA assumes no responsibility for the deletion or failure to store postings of content or other information submitted by you or other users to the IWA Site.</p>

        <p >If you are under 16, you must confirm that you have permission of your parent or guardian to submit content.</p>

        <p >Any queries regarding copyright and your content should be directed in the first instance to&nbsp;<a href="mailto:water@iwahq.org" >water@iwahq.org</a>.</p>

        <p ><span >4. Apps</span></p>

        <p >You may download certain IWA apps (&ldquo;Apps&rdquo;) either from IWA Sites or from third party app stores or shops. All of these terms apply to the maximum extent relevant to your use of the Apps (and in particular, the terms of section 3 apply where you are using an App to submit user content to the IWA Sites).</p>

        <p >In addition to the limitations on our liability set out in Section 2 and 10, we shall not be liable for any damage caused to or interference with any equipment or other Apps or content of any description.</p>

        <p ><span >5. Privacy and data protection</span></p>

        <p >Your privacy online is important to us and we want you to understand what, if any, information we collect and how it is used. For more information please see our&nbsp;<a href="https://iwa-network.org/privacy-notice/" rel="noopener noreferrer"  target="_blank"><span >Privacy Notice</span></a>&nbsp;and&nbsp;<a href="https://iwa-network.org/use-of-cookies/" ><span >Use of cookies</span></a>&nbsp;page.</p>

        <p ><span >6. Changes to these terms and conditions of use</span></p>

        <p >Please note that we may change these terms and conditions from time to time at our sole discretion and we reserve the right to do without your consent. Any revised terms and conditions will be applicable at the time of posting on the IWA Site. Please ensure that you review these terms and conditions regularly as you will be deemed to have accepted a variation if you continue to use the IWA Site after it has been posted.</p>

        <p ><span >7. Governing law &amp; jurisdiction</span></p>

        <p >These terms and conditions are governed by English law and the parties agree to submit to the exclusive jurisdiction of the English courts.</p>

        <p ><span >8. Indemnification</span></p>

        <p >You agree to defend, indemnify, and hold harmless the IWA, its subsidiaries, and affiliates, and each of their respective officers, directors, and employees from any and all claims, liabilities, costs, and expenses, including, but not limited to, attorneys&rsquo; fees and expenses, arising out a breach by you of these terms and conditions or privacy policy or arising out of a breach of your obligations, representation and warranties under these terms and conditions.</p>

        <p ><span >9. No waiver</span></p>

        <p >Our failure to insist upon or enforce any provision of these terms of service shall not be construed as a waiver of any provision or right of the IWA.</p>

        <p ><span >10. Limitation of liability</span></p>

        <p >You acknowledge and agree that, to the fullest extent permitted by applicable law, regardless of the form of action, whether in contract, tort (including negligence) or otherwise, in no event will the IWA, including, without limitation, their respective officers, directors, employees, successors and assigns, be liable to you or any other party for any direct or indirect loss, damage, cost, expense or liability of any kind (&ldquo;loss&rdquo;) arising in any way out of or in connection with the availability, use, reliance on, or inability to use the IWA site, including (without limitation): (i) damages for business interruption, loss of business and other profits, loss of programs, cost of replacing equipment or software or loss of records, information or data, loss of use of data, loss of revenue, loss of goodwill, loss of customers, loss of or damage to reputation, loss of capital, downtime costs, loss under or in relation to any other contract, or loss of anticipated savings or benefits; (ii) any indirect, special, exemplary, punitive, incidental or consequential loss; or (iii) any loss attributable to errors, omissions, or other inaccuracies in the IWA site. The exclusion of liability in this section 17 applies even if IWA shall have been advised of the possibility of such loss.</p>

        <p >Because some states or jurisdictions do not allow the exclusion or limitation of liability for particular kinds of loss, in such states or jurisdictions, IWA&rsquo;s liability shall be limited to the extent permitted by law (thereby minimizing IWA&rsquo;s liability to you to the lowest amount that applicable law permits).</p>

        <p >&nbsp;</p>

        <p >Copyright &copy; 2017 International Water Association. All rights reserved.</p>

      </Dialog>
    </>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
