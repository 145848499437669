import React from "react";

export default function footer() {
  return (
    <>
      {/* End Footer */}
      <div
        className="container-fluid mt-0 g-event"
        style={{ background: "#000" }}
      >
        <div className="container pt-4">
          <div className="row align-items-center">
            <div class="col-md-12 p-4 py-4">
              <p className="w-100 d-none d-md-block">
                <a
                  style={{ color: "#fff" }}
                  className="card-text text-align-start fw-bolder fs-3 me-3"
                  href="https://twitter.com/hashtag/worldwatercongress"
                >
                  #WorldWaterCongress
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a
                  style={{ color: "#fff" }}
                  className="card-text text-align-start fw-bolder fs-3 me-3"
                  href="https://twitter.com/IWAHQ"
                >
                  @IWAhq
                </a>
                &nbsp;&nbsp;&nbsp;
                <a
                  style={{ color: "#fff" }}
                  className="card-text text-align-start fw-bolder fs-3"
                  href="https://twitter.com/hashtag/iwacongress"
                >
                  #iwacongress
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
